import React from 'react';

export const SendIcon = () => (
  <svg width="628" height="581" viewBox="0 0 628 581" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M299.375 290.917H96.6553M90.9093 317.491L57.4173 417.537C39.0739 472.331 29.9023 499.727 36.4843 516.597C42.1999 531.251 54.4763 542.357 69.6246 546.587C87.0683 551.454 113.415 539.597 166.108 515.887L503.921 363.871C555.355 340.724 581.071 329.154 589.018 313.077C595.925 299.111 595.925 282.721 589.018 268.754C581.071 252.681 555.355 241.107 503.921 217.962L165.526 65.685C112.991 42.0447 86.7239 30.2243 69.2976 35.0733C54.1636 39.2843 41.8886 50.3627 36.1529 64.987C29.5483 81.8266 38.6219 109.164 56.7696 163.84L90.9743 266.894C94.0909 276.284 95.6496 280.981 96.2646 285.781C96.8106 290.044 96.8049 294.357 96.2483 298.617C95.6206 303.417 94.0503 308.107 90.9093 317.491Z"
      strokeWidth="66.6667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
