import { Deployment } from '../model/Project';

export const agelCommon: Deployment = {
  photo: 'agel.png',
  name: 'Agel',
  url: 'https://www.agel.cz/index.html',
};

export const npkCommon: Deployment = {
  photo: 'npk.jpg',
  name: 'Nemocnice Pardubického kraje',
  url: 'https://www.nempk.cz/',
};
