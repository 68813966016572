import { LanguageStrings } from './en';

export const cs: LanguageStrings = {
  /** Sections **/
  'section.about': 'O projektu',
  'section.transition': 'Přechod ze SLP®',
  'section.demo': 'Demo',
  'section.contact': 'Kontaktujte nás',
  'section.features': 'Funkce',
  'section.references': 'Reference',
  'section.deployments': 'Uživatelé slp.blue',
  'section.download': 'Ke stažení',
  'section.youtube': 'Video',
  'section.faq': 'FAQ',
  'section.teams': 'Tým',
  'section.documents': 'Dokumenty',
  'section.resources': 'Chcete vědět víc?',

  /** Sections content */
  'section.demo.desc': 'Nečekejte.\nVyzkoušejte naše demo.',

  /** Common */
  error: 'Jejda, něco se pokazilo...',
  'error.button': 'Znovu načíst stránku',

  'mild-blue.coop': 'Vytvářeno ve spolupráci s',
};
